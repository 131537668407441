<template>
  <v-card min-width="650">
    <v-card-title>Domain Details</v-card-title>
    <v-card-text>
      <v-row>
        <v-col>
          <v-form ref="form">
            <v-text-field
              v-model="form.domain_name"
              label="Domain Name"
              required
              :rules="domain_name_rules"
              :counter="255"
            />
            <v-text-field
              v-model="form.domain_description"
              label="Domain Description"
              :rules="domain_description_rules"
              :counter="255"
            />
          </v-form>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <define-domain
            required
            :rules="domain_rules"
            @confirmDomainData="confirmEverything"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { required, manageLenghtError } from '@/formRules.js';

export default {
  components: {
    DefineDomain: () => import('@/components/domain/DefineDomain')
  },
  props: {
    form: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      domain_name_rules: [...required, ...manageLenghtError(255)],
      domain_description_rules: [...manageLenghtError(255)],
      domain_rules: [...required]
    };
  },
  computed: {
    ...mapState(['domain'])
  },
  methods: {
    ...mapActions([
      'storeDomainDetails',
      'storeNewDomain',
      'storeUpdatedDomain',
      'storeToastMessage'
    ]),
    confirmEverything() {
      if (this.$refs.form.validate()) {
        this.storeDomainDetails(this.form);

        if (this.domain.isModifingDomain == true) {
          this.storeUpdatedDomain();
          this.$emit('closeDomainDettail');
        } else {
          this.storeNewDomain();
          this.$emit('closeDomainDettail');
        }
      } else {
        this.storeToastMessage({
          text: 'Please set required fields',
          type: 'warning'
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
